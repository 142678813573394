export const useLoading = <P extends unknown[], R>(
  proc: (...params: P) => Promise<R>
): { loading: Ref<boolean>; load: (...params: P) => Promise<R> } => {
  const loading = ref(false);
  const load = async (...params: P): Promise<R> => {
    loading.value = true;
    return await proc(...params).finally(() => (loading.value = false));
  };
  return { loading, load };
};
